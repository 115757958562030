import React from 'react'
import { StaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { GatsbyImage } from "gatsby-plugin-image"
import '../styles/global.css'

export default function ClientLogo() {
  return (
    <StaticQuery query={graphql`
        query ClientLogos {
            logo: allContentfulClientLogo {
                nodes {
                    clientLogo {
                        gatsbyImageData(width: 1024)
                    }
                }
            }
        }
      `}
      render={data => (
        data.logo.nodes[0].clientLogo.gatsbyImageData ?
        <GatsbyImage image={data.logo.nodes[0].clientLogo.gatsbyImageData} alt="client-logo" placeholder="blurred"/> :
        <StaticImage src="../images/client-logo.png" alt="client-logo" placeholder="blurred"/>
      )
    }
    />
  )
}

