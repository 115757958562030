import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import {Helmet} from "react-helmet"
import serviceanimation from "../images/service-animation.svg"
import servicevideo from "../images/service-video.svg"
import servicegraphic from "../images/service-graphic.svg"
import stepone from "../images/steps-1.svg"
import steptwo from "../images/steps-2.svg"
import stepthree from "../images/steps-3.svg"
import Footer from "../components/Footer"
import MainHeader from "../components/MainHeader"
import '../styles/global.css'
import ButtonView from "../components/ButtonView"
import ClientLogo from "../components/ClientLogo"

export default function Home() {
  return (
  <>
  <Helmet
      htmlAttributes={{lang:"en"}}
      title="PlanetFx - Home Page"
      meta={[{name:"viewport", content:"width=device-width, initial-scale=1.0"}, {name:"description", content:"Planet Fx adalah studio kreatif pembuatan Company Profile, Iklan, Explainer, ILM, Teaser, Bumper berupa Animasi Flat Motion Graphic dan juga pembuatan Media Interaktif dan Design"}]}>
    </Helmet>
    <MainHeader/>
      <main>
        <div className="layout">
          <section className="service">
            <article className="we-are">
                <h4>Kami adalah studio kreatif Motion Graphic Animation, Videography dan Design Graphic dari Sidoarjo </h4>
                <hr/>
              </article>
              <article>
                  <h2>Layanan Kami</h2>
                  <hr/>
              </article>
              <article className="service-content">
                  <aside>
                      <figure>
                          <img src={serviceanimation} alt=""/>
                      </figure>
                      <h4>Animasi Motion Graphic</h4>
                      <p>Explainer, Company Profile, Iklan, Video Jinggle, Animasi Edukasi</p>
                  </aside>
                  <aside>
                      <figure>
                          <img src={servicevideo} alt=""/>
                      </figure>
                      <h4>Videography</h4>
                      <p>Company Profile, Dokumentasi, Iklan Product/Jasa, Video Edukasi</p>
                  </aside>
                  <aside>
                      <figure>
                          <img src={servicegraphic} alt=""/>
                      </figure>
                      <h4>Design Graphic</h4>
                      <p>Visual Identitas, Materi Pemasaran, Album Kenangan, Illustrasi</p>
                  </aside>
              </article>
          </section>
          <section className="client">
              <article>
                  <h2>Klien</h2>
                  <p>Kita Bekerja dengan team di seluruh Indonesia</p>
                  <hr/>
              </article>
              <figure>
                  {/* <StaticImage src="../images/client-logo.png" alt="client-logo" placeholder="blurred"/> */}
                  <ClientLogo/>
              </figure>
          </section>
          <section className="work">
              <article>
                  <h2>Karya Terbaru Kami</h2>
                  <hr/>
              </article>
              <article className="work-content">
                  <aside className="work-contents">
                        <h3>Animasi Motion Graphic</h3>
                        <p>Explainer, Company Profile, Iklan, Video Jinggle, Animasi Edukasi</p>
                        <ButtonView className="btn-orange" target="/animation#content" />
                  </aside>
                  <figure className="order">
                      <StaticImage src="../images/work-animation.png" alt="Motion Graphic" placeholder="blurred"/>
                  </figure>
              </article>
              <article className="work-content">
                  <figure>
                      <StaticImage src="../images/work-video.png" alt="Videography" placeholder="blurred"/>
                  </figure>
                  <aside className="work-contents">
                        <h3>Videography</h3>
                        <p>Company Profile, Dokumentasi, Iklan Product/Jasa, Video Edukasi</p>
                        <ButtonView className="btn-indigo" target="/videography#content" />
                  </aside>
              </article>
              <article className="work-content">
                  <aside className="work-contents">
                        <h3>Design Graphic</h3>
                        <p>Visual Identitas, Materi Pemasaran, Album Kenangan, Illustrasi</p>
                        <ButtonView className="btn-orange" target="/graphic#content" />
                  </aside>
                  <figure className="order">
                      <StaticImage src="../images/work-graphic.png" alt="Design Graphic" placeholder="blurred"/>
                  </figure>
              </article>
          </section>
          <section className="steps">
              <article>
                  <h2>3 Langkah Cepat pembuatan<br/>Animation, Video, Design</h2>
                  <p>Dengan alur dan komunikasi padat, maka proses produksi menjadi efisien</p>
                  <hr/>
              </article>
              <article className="steps-content">
                  <aside>
                      <img src={stepone} alt="Send Material"/>
                      <h4>Kirim materi anda</h4>
                  </aside>
                  <aside>
                      <img src={steptwo} alt="Production Process"/>
                      <h4>Proses Produksi</h4>
                  </aside>
                  <aside>
                      <img src={stepthree} alt="Ready to Go"/>
                      <h4>Pesanan anda siap digunakan </h4>
                  </aside>
              </article>
          </section>
        </div>
      </main>
    <Footer/>
  </>
  )
}
