import { Link } from 'gatsby'
import React from 'react'
import '../styles/global.css'

export default function ButtonView( {className, target} ) {
    return (
        <Link to={target}>
            <button className={className}>View Project</button>
        </Link>
    )
}