import React from 'react'
import '../styles/global.css'
import ButtonTalk from './ButtonTalk'
import Navbar from './Navbar'

export default function MainHeader() {
    return (
        <header className="home-header">
            <Navbar />
            <section className="banner">
                <h1><span>WE ARE</span> PLANET FX</h1>
                <h5>Beyond Imagine</h5>
                <ButtonTalk className="btn-white"/>
            </section>
            
        </header>
    )
}